import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import UIkit from "uikit/dist/js/uikit"

import * as styles from './nav.module.scss'

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pathname: "" };
  }

  getMenu(data) {
    const currentPath = this.props.path;

    return data.allStrapiMenuItem.nodes.map((menuItem, i) => {
      const url = menuItem.article != null ? menuItem.article.url : "/404";
      return (
        <li key={menuItem.strapiId}>
          <Link
            to={`${url}`}
            onClick={this.closeSideMenu}
            className={currentPath === url || (url !== "/" && currentPath.startsWith(url)) ? styles.menuTextSelectedPage : styles.menuText}>
            {menuItem.title}
          </Link>
        </li>
      )
    })
  }

  closeSideMenu() {
    UIkit.offcanvas('#sidenav').hide();
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
        {
          allStrapiMenuItem(sort: {fields: id}) {
            nodes {
              title
              strapiId
              article {
                url
              }
            }
          }
          site {
            siteMetadata {
              title
            }
          }
        }   
        `}
        render={data =>
          <>
            {/* Main navigation bar */}
            <div className={styles.fullMenuBar}>
              <div className={styles.menu}>
                <nav className={styles.navbar + " uk-navbar-container uk-visible@m"} data-uk-navbar>
                  <div className="uk-navbar-left">
                    <ul className="uk-navbar-nav">
                      <li>
                        <Link to="/" className={styles.websiteTitleText}>
                          <img src="/logo-100x100.png" className={styles.logo} alt={data.site.siteMetadata.title} />
                          {data.site.siteMetadata.title}
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav">
                      {this.getMenu(data)}
                    </ul>
                  </div>
                </nav>
              </div>
            </div >

            {/* Navigation bar for small screens */}
            <div className={styles.navbar + " uk-navbar-container uk-hidden@m"} uk-navbar="true">
              <div className="uk-navbar-left">
                <button className="uk-navbar-toggle" uk-navbar-toggle-icon="true" uk-toggle="target: #sidenav" aria-label="Open menu"></button>
                <div className="uk-navbar-left">
                  <ul className="uk-navbar-nav">
                    <li>
                      <Link to="/" className={styles.websiteTitleText}>
                        <img src="/logo-100x100.png" className={styles.logo} alt={data.site.siteMetadata.title} />
                        <div className={styles.siteName}>{data.site.siteMetadata.title}</div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Menu for the small navigation bar */}
            <nav id="sidenav" uk-offcanvas="true" className={styles.offcanvasMenu}>
              <div className="uk-offcanvas-bar">
                <button className="uk-offcanvas-close" type="button" uk-close="true" aria-label="Sluit menu"></button>
                <ul className="uk-nav">
                  {this.getMenu(data)}
                </ul>
              </div>
            </nav>
          </>
        }
      />
    )
  }
}

export default Nav
