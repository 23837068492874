import React from 'react'

import AnalyticsContext from "../context/analytics-context"

class AnalyticsPageReporter extends React.Component {
    render() {
        return (
            <AnalyticsContext.Consumer>
                {analyticsContext => {
                    const currentPath = this.props.path;

                    if (currentPath != "/*") {
                        analyticsContext.postEvent("PageVisit", {
                            path: currentPath
                        });
                    }

                    return (
                        <>
                            {this.props.children}
                        </>)
                }}
            </AnalyticsContext.Consumer>
        )
    }
}

export default AnalyticsPageReporter