import React from "react"

class UikitWrapper extends React.Component {
    componentDidMount() {
        try {
            this.UIkit = require('uikit/dist/js/uikit')
            this.Icons = require('uikit/dist/js/uikit-icons')
            this.UIkit.use(this.Icons)
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        return (
            <>
                {this.props.children}
            </>
        )
    }
}

export default UikitWrapper