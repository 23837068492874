/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { AnalyticsProvider } from "./src/context/analytics-context"
import AnalyticsPageReporter from "./src/components/analytics-page-reporter"
import Nav from "./src/components/nav"
import UiKitWrapper from "./src/components/uikitWrapper"

import "./src/components/global-style.scss"

export const onClientEntry = () => {
    console.log("We've started!")
}

export const wrapRootElement = ({ element }) => (
    <AnalyticsProvider>
        <UiKitWrapper>
            {element}
        </UiKitWrapper>
    </AnalyticsProvider>
)

export const wrapPageElement = ({ element, props }) => {
    return (
        <AnalyticsPageReporter path={props.path}>
            <Nav path={props.path} />
            {element}
        </AnalyticsPageReporter>
    )
}
