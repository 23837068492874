import React from "react"
import axios from 'axios';

const defaultState = {
    postEvent: (name, data) => { },
};

const AnalyticsContext = React.createContext(defaultState);

class AnalyticsProvider extends React.Component {
    postEvent(name, data) {
        const event = {
            name: name,
            data: data,
            time: Date.now()
        }

        console.log("Analytics", event);

        const sendToEmulator = false;
        const postUrl = sendToEmulator
            ? "http://localhost:6001/photography-website-274717/europe-west1/websiteAnalyticsEvent"
            : "https://europe-west1-photography-website-274717.cloudfunctions.net/websiteAnalyticsEvent";

        axios.post(postUrl, event)
            .then((result) => {
                console.log("Analytics event send to backend", result);
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    sendEventToBackend(event) {

    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            var referrer = document.referrer;
            if (!referrer.includes("localhost") &&
                !referrer.includes("peterfortuin.nl") &&
                referrer !== "") {
                this.postEvent("WebPageReferrer", {
                    referrer: referrer
                })
            }
        }
    }

    render() {
        const { children } = this.props

        return (
            <AnalyticsContext.Provider value={{
                postEvent: this.postEvent,
            }}>
                {children}
            </AnalyticsContext.Provider>
        );
    }
}

export default AnalyticsContext
export { AnalyticsProvider }
